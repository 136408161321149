import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.div`
  position: relative;
  font-size: 14px;
  text-align: center;
  margin-top: 5rem;
`;

export default function Footer() {
  return (
    <FooterStyles>
      <footer>&copy; Copyright {new Date().getFullYear()} JKSTR</footer>
    </FooterStyles>
  );
}
