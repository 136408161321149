import React from 'react';
import styled from 'styled-components';
import Nav from './Nav';
import Footer from './Footer';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const SiteBorderStyles = styled.div`
  /*  */
`;

const ContentStyles = styled.div`
  background: transparent;
  /* padding: 1rem 2rem 1rem 10rem; */
  position: relative;
`;

export default function Layout({ children }) {
  const [toggle, setToggle] = React.useState(false);

  function toggleMenu(newValue) {
    setToggle(newValue);
  }

  return (
    <div className="outer-container">
      <Typography />
      <GlobalStyles />

      <Nav toggle={toggle} onChange={toggleMenu} />
      <div className={`wrapper ${toggle ? 'active' : ''}`}>
        <div className="content">
          <SiteBorderStyles>
            <ContentStyles>
              {children}

              <Footer />
            </ContentStyles>
          </SiteBorderStyles>
        </div>
      </div>
    </div>
  );
}
