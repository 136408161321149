import styled, { createGlobalStyle } from 'styled-components';

// import bg from '../assets/images/bg.svg';
// import stripes from '../assets/images/stripes.svg';

const Grid = styled.div`
  max-width: 1200px;
`;

const media = {
  xs: (styles) => `
  @media only screen and (max-width:770px){
    ${styles}
  }
  `,
};
const Row = styled.div`
  display: flex;
  ${(props) => props.stack && media[props.stack](`flex-direction:column;`)}
`;

const Col = styled.div`
  flex: ${(props) => props.size};
  margin: ${(props) => props.margin};
  ${(props) => props.stack && media[props.stack](`flex-shrink: 0; `)};
  ${(props) => props.collapse && media[props.collapse](`display:none;`)};
`;

const GlobalStyles = createGlobalStyle`
  :root {

    --bordercolor:#393939;
    --accent-border:#ccc;
    --accentcolor:#ccc;
    --primarytext:#2E2E2E;

    
    
    
  }

  html, body {
    max-width: 100%;
    overflow-x: hidden;
}
  html {
    font-size: 12px;
    margin:0;
    padding:0;
  }

  body {
    font-size: 1.4rem;
    color:var(--primarytext);
    font-family: 'Inter';
    
    
  }

  h2 {
    font-size:2em;
    font-weight:600;
  }

p, ul{
  font-size:1.6rem;
  line-height:1.7;
  font-weight: 500;
}


  .drop-cap:first-letter  {
  
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  float: left;
  font-size: 7em;
  font-weight:600;
  line-height: 0.65;
  margin: 0.1em 0.1em 0.1em 0;
}


  a{
  &:focus { 
    outline: none !important;
    }import { BaseBlockContent } from '@sanity/block-content-to-react';

}
.outer-container {
  max-width: 100%;
    overflow-x: hidden;
  
}

.wrapper {
position: relative;
display:block;
overflow-x: hidden;
z-index: 1;
padding-left:90px;
transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  transition-delay: 100ms;
}

.content {
  
  position:relative;
 padding:2rem;
  z-index:0;

}
.wrapper.active {
  transform: matrix(1, 0, 0, 1, 200, 0);
}

 
.learn-more a {
  
  
  font-size:1rem;
  letter-spacing:2px;
  position:absolute;
  bottom:0;
  background:var(--lightpurple);
  width:100%;
  color:#000;
  text-align:left;
  margin-left:-15px;
  padding:5px 0px 5px 30px;
}

.learn-more a:before {
  position:absolute;
  font-size:1.3rem;
  top:3px;
  left:10px;
  content:'▣ ';
}

.title-link {
  text-decoration:none;
  position:relative;
  color:var(--primarytext);
  width:auto;
  display:block;
  font-size:1.7em;
  font-weight:600;
  padding-top:1rem;
  padding-bottom:4rem;
  padding-left:30px;
  
}

.title-link:before{
  content:"";
  position:absolute;
  left:0px;
  top:20%;
  width:20px;
  height:20px;
  display:block;
  border-radius:100%;
  background:transparent;
  border:1px solid var(--bordercolor);
}

.link-hex {
   --aug-all-width: 20px;
  --aug-border-all: 1px;
}

.filter {
   margin-left:1rem;
   margin-right:1rem;
  
}

.filter  {
    
   
    
    
   
    font-weight: 100;
    
    display:block;
    padding: 1.5rem;
    line-height: 1rem;
  
    
       & a[aria-current='page'] {
font-weight: 500;
         border-bottom:1px solid var(--bordercolor);
       }
         
     
  }


  .block{
    display:block;
    float:left;
  }

  .row-decoration-1 {
    margin-top: 5rem;
    border-top: 1px solid var(--bordercolor);
    padding-top: 5rem;
  }

  .row-interior-header {
position:relative;
    margin-top: 3rem;
    border-top: 1px solid var(--bordercolor);
    padding-top: 5rem;

  }

    .row-interior-header:before{
      content:'';
      display:block;
      position:absolute;
      top:-20px;
      left:-5px;;
      height:40px;
      width:40px;
      background:var(--bordercolor);
      border-radius:100%;
    }

    .preview-img {
    border: 1px solid var(--bordercolor);
  }

.awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:before, .awssld__controls__arrow-right:after   {
  --organic-arrow-color:#fff;

}

.awssld__controls__arrow-left, .awssld__controls__arrow-right {
  --organic-arrow-height:20px;
 --organic-arrow-width:20px;
}
.awssld__controls button {
  --control-button-width:50px;
  --control-button-height:50px;
}
.awssld__controls button {
  border-bottom:0;
  border-right:0;
  border-top:0;
  border-left:0;
  box-shadow:none;
}


  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  video {
    top:50%;
    position:relative;
    transform: translateY(-50%);
    /* filter: grayscale(1); */
    opacity:.2;
}

  button {
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
  
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }


  /* Link button */

  a.btn, button {
  background-color: var(--bordercolor);
  color: white;
  padding: 1em 1.5em;

  text-decoration: none;
  text-transform: uppercase;
}

a.btn:hover {
  background-color: #555;
}

a.btn:active {
  background-color: black;
}

/* a.btn:visited {
  background-color: #ccc;
} */


  /* Helpers */


h3.lrg-text {
  font-size:1.9rem;
}

  .lrg-text{
    font-weight:700;
    font-size:1.8rem;
  }

.small-text {
  font-size:.9rem;
}
  .center {
    align-items: center;
    justify-content: center;
  }

  .center-self {
    margin:auto;
    display:block;
  }

  .text-center {
    text-align:center;
  }

  .text-right {
    text-align:right;
  }

  .caps {
    text-transform:uppercase;
  }

  .small {
    font-size:14px;
  }

  .bold {
    font-weight:bold;
  }

  .p-10 {
    padding:10px;
  }
  .p-20 {
    padding:5rem;
  }
  .border-right {
    border-right:1px solid var(--bordercolor)
  }


 

  

  

  hr {
    border: 0;
    height: 8px;
    
    background-size: 1500px;
  }

  .vert-list{
padding:0;

  li{
    padding:15px;
border:1px solid var(--bordercolor);
border-collapse: collapse;


  }}

  .vert-list li + li{
  border-top: none;
}

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .side-text {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: -50px;
    transform: rotate(270deg);
    transform-origin: 0 0;
  }


 .aug-glow {
    filter: drop-shadow(0 0 4px skyblue) drop-shadow(0 0 1px blue)
      brightness(1.5);
  }

  /* Border Styles */


  .header-border {
    
  }

  .default-border {
    --aug-border-all: 1px;
    
  }


/* Post Previews */
 .thumb {
    --aug-t: 25px;
    --aug-b: 25px;
    /* margin-right: auto; */
    overflow: hidden;
    margin: auto;
    height: auto;
    --aug-border-all: 1px;
    --aug-border-bg: var(--lightpurple);
    /* --aug-border-bg: linear-gradient(to bottom right, teal, rebeccapurple); */
  }

  .post-thumb {
    --aug-all-height: 100px;
    --aug-border-all: 2px;
    height: 100px;
    margin: 20px;
  }
  

/* Silly Effects */

/* Underline styles */
a.fancy {
  font-weight:500;
  font-size:1.5em;
  text-decoration:none;
  display: block;
  position: relative;
  padding: 0.2em 0;
  overflow:hidden;
  width:auto;
}

/* Fade in */
a.fancy::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--bordercolor);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  
  transform: translate3d(-100%, 0, 0);
}

a.fancy:hover::after,
a.fancy:focus::after {
  opacity: 1;
   transform: translate3d(0, 0, 0);
}

.wrapper:after {
    content:"";
    position: absolute;
    background: var(--bordercolor);
    width: 250px;
    height: 250px;
    position: absolute;
    display: block;
    top: -120px;
    right: -120px;
    padding:0;
    z-index: 0;
    border-radius: 50%;
  }

  .circle-divider {
  background: linear-gradient(to right, transparent 0%,transparent 2%,var(--bordercolor) 3%,var(--bordercolor) 100%);
  
  display:block;
  height:1px;
  margin:0px 40px 30px 40px;
  clear:both;

}

.circle-divider:before {
  position:absolute;
  left:0;
  top:-20px;
  content:"";
  width:40px;
  height:40px;
  border-radius:50%;
  display:block;
  background:var(--bordercolor);
}

.square-divider {
  position:relative;
  border-bottom:1px solid var(--bordercolor);
  width:66vw;
  display:block;
  height:20px;
  margin:20px 40px 40px 0px;
  
}

.square-divider:before {
  position:absolute;
  content:"";
  bottom:0;
  right:0;
  width:40px;
  height:40px;
  display:block;
  background:var(--bordercolor);
  
}

.react-images__pager button svg {position:absolute;}

body > div.fullscreen > div.react-images__positioner img {
  background:#fff;
}

.design-modal {
    max-width: 100% !important;
    width:90%;
  }

  .react-responsive-modal-closeButton {
    position: fixed!important;
    background:#fff;
  }
  .react-responsive-modal-closeButton svg{
    height:50px;
    width:50px;
  }

.hover-line-animation {
  padding:20px;
  display: inline-block;
  position: relative;
  color: var(--bordercolor);
  border-bottom: 1px solid var(--bordercolor);
  
}

.hover-line-animation:before {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  top: 0;
  right: -1px;
  background-color: var(--bordercolor);
  transform-origin: top left;
  transition: transform 0.25s ease-out;
}

.hover-line-animation:hover:before{
  transform: scaleX(1);
  transform-origin: top right;
}

.hover-line-animation:after {
  content: '';
  position: absolute;
  width: 1px;
  transform: scaleY(0);
  height: 70%;
  top: 0;
  right: -1px;
  background-color: var(--bordercolor);
  transform-origin: top right;
  transition: transform 0.25s ease-out;
}

.hover-line-animation:hover:after {
  transform: scaleY(1);
  transform-origin: top right;
}
.hover-line-animation:hover .title-link:before{
  
  background:var(--bordercolor);
}



/* Create four equal columns */




.column {
  flex: 25%;
  padding: 10px;
}



@media screen and (max-width: 926px) {
  
  .sm-top-border {
    border-top:1px solid var(--lightpurple);
  }
}

@media only screen and (max-width: 1024px) {
.wrapper {
  padding:1%;
  /* margin-top:80px; */
}
.content {
  padding:3%;
  /* margin-top:80px; */
}
.hover-line-animation {
  padding:5px;
}
.intro {
  margin-top:0;
}

.row-decoration-1 { 
   
    border-top: 0;
    
  }

  .row-interior-header{
    margin-top:70px;

  }

  .drop-cap:first-letter  {
  
  
  font-size: 3em;
  font-weight:600;
  line-height: 0.65;
  margin: 0.1em 0.1em 0.1em 0;
}

   /* .row-interior-header:before{
      content:'';
      display:block;
      position:absolute;
      top:10px;
      left:-5px;;
      height:40px;
      width:40px;
      background:var(--bordercolor);
      border-radius:100%;
    } */

}



/* On screens that are 770px wide or less, go from four columns to two columns */
@media screen and (max-width: 770px) {
  .text-right {
    text-align:left;
  }
  .sm-top-border {
    border-top:1px solid var(--lightpurple);
  }
}

/* On screens that are 480px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 480px) {
  .row {
    flex-direction: column;
  }
}




`;

export default GlobalStyles;
export { Grid, Row, Col };
