import { createGlobalStyle } from 'styled-components';

// import font from '../assets/fonts/Inter-SemiBold.woff';

const Typography = createGlobalStyle`
  
  html {
    font-family: 'Inter', sans-serif;
    
  }
   .mega-text {
    font-family: 'Inter', sans-serif;
    font-size:6rem;
    opacity:.2;
    text-align:right;
  }

  .section-title {
    font-size:3em;
    
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
    margin: 0;
  }
  a {
    color: var(--red);
    
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
