/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavStyles = styled.div`
  .main {
    overflow: hidden;
    width: 100%;
  }
  .menu {
    position: fixed;
    border-right: 1px solid gray;
    width: 200px;
    left: -200px;
    top: 5px;
    height: 100%;
    z-index: 4;
    transition: transform 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
    transition-delay: 100ms;
  }
  .nav {
    top: 0px;
    padding-top: 80px;
    transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
    transition-delay: 100ms;
  }

  .menu.active {
    transform: matrix(1, 0, 0, 1, 200, 0);
  }
  .menu-bar {
    border-right: 1px solid #666;
    position: absolute;
    width: 80px;
    left: 100%;
    z-index: 1;
    top: 0px;
    bottom: 0px;
  }
  .icon-1,
  .icon-2,
  .icon-3 {
    position: absolute;
    left: 25%;
    top: 50%;
    width: 32px;
    height: 3px;
    background-color: black;
    transition: transform 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
  }
  .icon-1 {
    transform: translateY(-8px);
    animation-delay: 100ms;
  }

  .icon-3 {
    transform: translateY(8px);
    animation-delay: 250ms;
  }

  .hamburger-icon {
    position: absolute;
    height: 60px;
    width: 60px;
    top: 2%;
    left: 15%;
    z-index: 1000;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
    &:focus {
      outline: none !important;
    }

    &:hover {
      transform: scale(1.2);
    }
  }

  .icon-1.a {
    transform: rotate(40deg);
  }
  .icon-3.b {
    transform: rotate(-40deg);
  }
  .icon-2.c {
    opacity: 0;
  }

  h1 {
    color: #000;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: absolute;
    top: 220px;
    left: 0;
    margin-left: -60px;
    padding-bottom: 10px;
    transform: rotate(270deg);
    width: 200px;
  }

  h1:after {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.5rem;
    content: 'Portfolio of Jack Strube';
  }
  ul {
    list-style: none;
    padding: 1rem;
  }
  ul li {
    margin-bottom: 0.5rem;
  }
  ul li a {
    padding: 5px;
    font-size: 2.5rem;
    color: #000;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
  }

  .logo-container {
    /* position: absolute; */
    display: block;
    margin: 100px auto;
    width: 60px;
    height: 60px;
    top: 100px;
    border-bottom: 2px solid #666;
  }

  @media only screen and (max-width: 1024px) {
    .menu {
      width: 100vw;
      height: 100%;
      pointer-events: none;
      left: 0;
      top: 0;
      border-right: 0px solid gray;
    }

    .menu * {
      pointer-events: all;
    }

    .menu.active {
      transform: none;
    }

    .menu .nav {
      position: fixed;
      left: -200px;
      width: 200px;
      height: 100%;
      z-inde: 5;

      border-right: 1px solid var(--bordercolor);
    }

    .menu.active .nav {
      transform: matrix(1, 0, 0, 1, 200, 0);
    }
    .menu-bar {
      position: absolute;
      border-bottom: 1px solid #666;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 100%;
      top: 0;
      left: 0;
      height: 58px;
      background-color: #fff;
    }

    .hamburger-icon {
      left: 0;
      top: 0px;
    }
    h1 {
      font-size: 2rem;
      top: 10px;
      left: 150px;
      transform: rotate(0deg);
    }
  }
`;

export default function Nav(props) {
  const [toggle, setToggle] = React.useState(false);

  function toggleMenu() {
    setToggle(!toggle);
    props.onChange(!toggle);
  }

  return (
    <NavStyles>
      <header className={`menu ${toggle ? 'active' : ''}`}>
        <div className="menu-bar">
          <div
            role="button"
            tabIndex="0"
            className="hamburger-icon"
            id="icon"
            onClick={() => {
              toggleMenu();
            }}
          >
            <div className={`icon-1 ${toggle ? 'a' : ''}`} />
            <div className={`icon-2 ${toggle ? 'c' : ''}`} />
            <div className={`icon-3 ${toggle ? 'b' : ''}`} />
            <div className="clear" />
          </div>
          <div className="logo-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 257">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M275.58,257a6.37,6.37,0,0,1-3.32-1.47l-73.42-78.4a65.53,65.53,0,0,1-4.22-5.15c0-.29-.05-.74-.05-1.38a4.84,4.84,0,0,1,1.32-3.59,7.38,7.38,0,0,1,.88-.82,47.47,47.47,0,0,1,6.52-.58h80.37c5,0,9.08-4.34,9.08-9.68v-4.3c0-5.33-4.07-9.68-9.08-9.68h-102c-5,0-9.08,4.35-9.08,9.68V257H146.37V151.63c0-5.33-4.07-9.68-9.08-9.68h-102c-5,0-9.08,4.35-9.08,9.68v4.3c0,5.34,4.07,9.68,9.08,9.68H115.7a47.47,47.47,0,0,1,6.52.58,8.08,8.08,0,0,1,.89.82,4.87,4.87,0,0,1,1.31,3.59c0,.64,0,1.09,0,1.38a65.53,65.53,0,0,1-4.22,5.15l-73.42,78.4A6.37,6.37,0,0,1,43.41,257H8.16l47.08-50.14a7.59,7.59,0,0,0,1.92-8,6.78,6.78,0,0,0-6.67-4.21H3.09c-1,0-2.09,0-3-.1-.06-1-.1-2.11-.1-3.19v-74c0-1.09,0-2.23.1-3.2.91-.06,2-.11,3-.11H316.89c1,0,2.1,0,3,.11.06,1,.1,2.11.1,3.2v74c0,1.08,0,2.21-.1,3.19-.9.06-2,.1-3,.1h-47.4a6.78,6.78,0,0,0-6.67,4.21,7.59,7.59,0,0,0,1.92,8L311.84,257Z" />
                  <path d="M282.42,103a10.52,10.52,0,0,1-5.72-2.12L236.41,67.22a7.24,7.24,0,0,0-8.72-.27L182.24,101a12,12,0,0,1-6,2H2.34a2,2,0,0,1-2-2V64a2,2,0,0,1,2-2h23a2,2,0,0,1,2,2V74a6,6,0,0,0,6,6h106a6,6,0,0,0,6-6V29a6,6,0,0,0-6-6h-101a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h138a2,2,0,0,1,2,2V64.68a4.81,4.81,0,0,0,.8,2.84,3.35,3.35,0,0,0,2.76,1.42,4.78,4.78,0,0,0,2.84-1.06L273.66,2a12.1,12.1,0,0,1,6-2h39.46l-61,44.38a5,5,0,0,0-.24,8.1L319.66,103Z" />
                </g>
              </g>
            </svg>
          </div>

          <h1>JKSTR</h1>
        </div>
        <nav className={`nav `}>
          <ul>
            <li>
              <Link
                to="/"
                activeClassName="link-active"
                onClick={() => {
                  toggleMenu();
                }}
                partiallyActive
              >
                Home
              </Link>
            </li>
            {/* <li>
              <Link
                to="/about"
                activeClassName="link-active"
                onClick={() => {
                  toggleMenu();
                }}
                partiallyActive
              >
                About
              </Link>
            </li> */}

            <li>
              <Link
                to="/work"
                activeClassName="link-active"
                onClick={() => {
                  toggleMenu();
                }}
                partiallyActive
              >
                Work
              </Link>
            </li>

            {/* <li>
              <Link
                to="/blog"
                activeClassName="link-active"
                onClick={() => {
                  toggleMenu();
                }}
                partiallyActive
              >
                Blog
              </Link>
            </li> */}

            <li>
              <Link
                to="/resume"
                activeClassName="link-active"
                onClick={() => {
                  toggleMenu();
                }}
                partiallyActive
              >
                Resume
              </Link>
            </li>

            <li>
              <Link
                to="/gallery"
                activeClassName="link-active"
                onClick={() => {
                  toggleMenu();
                }}
                partiallyActive
              >
                Gallery
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                activeClassName="link-active"
                onClick={() => {
                  toggleMenu();
                }}
                partiallyActive
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </NavStyles>
  );
}
